import Rollbar from 'rollbar'

export function getRollbar() {
  return new Rollbar({
    accessToken: process.env.ROLLBAR_SERVER_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.ENV,
    },
  })
}

export function getRollbarClient() {
  return new Rollbar({
    accessToken: process.env.ROLLBAR_CLIENT_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.ENV,
    },
  })
}

type LogLevel = 'log' | 'debug' | 'info' | 'warn' | 'error' | 'critical'

function log(lvl: LogLevel, args: Rollbar.LogArgument) {
  if (lvl === 'error' || lvl === 'critical') {
    getRollbar()[lvl](args)
  }
}

export default {
  log: args => log('log', args),
  debug: args => log('debug', args),
  info: args => log('info', args),
  warn: args => log('warn', args),
  error: args => log('error', args),
  critical: args => log('critical', args),
}
