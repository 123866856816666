import mixpanel from 'mixpanel-browser'
import AirtableClient from '../src/api/AirtableClient'
import updateCompany from '../components/query/updateCompany'
import updateStudent from '../components/query/updateStudent'
import { UserType } from '../lib/UserType'
import { getRollbarClient } from './server/rollbar'

const getAnalytics = () => {
  // disable analytic for local builds
  // if (window.location.hostname === 'localhost') return null
  // return window.analytics
  // return mixpanel
  const segment = window.analytics
  return {
    identify(userId: string, traits: Record<string, any>): void {
      try {
        segment.identify(userId, traits)
        mixpanel.identify(userId, traits)
      } catch (e) {
        getRollbarClient().error(e)
      }
    },
    track(event: string, properties?: Record<string, any>): void {
      try {
        segment.track(event, properties)
        mixpanel.track(event, properties)
      } catch (e) {
        getRollbarClient().error(e)
      }
    },
    reset(): void {
      try {
        segment.reset()
        mixpanel.reset()
      } catch (e) {
        getRollbarClient().error(e)
      }
    },
  }
}

type ProfileViewSource = 'best matching' | 'explore' | 'direct'

export const reset = (): void => {
  const analytics = getAnalytics()
  if (!analytics) return

  analytics.reset()
}

export const trackLogoutEvent = (): void => {
  const analytics = getAnalytics()
  if (!analytics) return

  analytics.track('User logged out')
}

export const identifyUser = (userId: string, email: string, userType: UserType): void => {
  const analytics = getAnalytics()
  if (!analytics) return

  analytics.identify(userId, {
    email,
    userType,
  })
}

export const trackMagicLinkRequestedEvent = (email: string, userType: UserType): void => {
  const analytics = getAnalytics()
  if (!analytics) return

  if (userType === UserType.Startup) {
    analytics.track('Startup requested magic link', {
      email,
    })
  } else if (userType === UserType.Student) {
    analytics.track('Student requested magic link', {
      email,
    })
  }
}

export const trackLoginEvent = async (userType: UserType, recordId: string): Promise<void> => {
  const analytics = getAnalytics()

  if (userType === UserType.Startup) {
    if (analytics) analytics.track('Startup logged in')
    await updateCompany(recordId, { 'Last Login': new Date() })
  } else if (userType === UserType.Student) {
    if (analytics) analytics.track('Student logged in')
    await updateStudent(recordId, { 'Last Login': new Date() })
  }
}

export const trackStudentView = (studentId: string, sourceTab: ProfileViewSource): void => {
  const analytics = getAnalytics()
  if (!analytics) return

  analytics.track('Startup opens Student profile view', {
    studentId,
    sourceTab,
  })
}

export const trackStartupView = (startupId: string, sourceTab: ProfileViewSource): void => {
  const analytics = getAnalytics()
  if (!analytics) return

  analytics.track('Student opens Startup profile view', {
    startupId,
    sourceTab,
  })
}

export const trackJobOpportunityView = (startupId: string, jobOpportunityId: string): void => {
  const analytics = getAnalytics()
  if (!analytics) return

  analytics.track('Job Opportunity View', {
    startupId,
    jobOpportunityId,
  })
}

export const trackApplyJobOpportunity = (startupId: string, jobOpportunityId: string): void => {
  const analytics = getAnalytics()
  if (!analytics) return

  analytics.track('Job Opportunity Apply', {
    startupId,
    jobOpportunityId,
  })
}

export const trackJobOpportunityCopyLink = (startupId: string, jobOpportunityId: string): void => {
  const analytics = getAnalytics()
  if (!analytics) return

  analytics.track('Job Opportunity Copy Link', {
    startupId,
    jobOpportunityId,
  })
}

export const trackOpenStartupCareersPage = (startupId: string): void => {
  const analytics = getAnalytics()
  if (!analytics) return

  analytics.track('Startup Open careers page', {
    startupId,
  })
}

export const trackSendStartupMessage = (startupId: string): void => {
  const analytics = getAnalytics()
  if (!analytics) return

  analytics.track('Startup Send Message', {
    startupId,
  })
}

export const trackFilterStartupsByOneTag = (studentId: string, tag: string, section: string): void => {
  const analytics = getAnalytics()
  if (!analytics) return

  analytics.track('Set tag to filter startups', {
    studentId,
    tag,
    section,
  })
}

export const trackFilterStartupsByTags = (studentId: string, filters: string): void => {
  const analytics = getAnalytics()
  if (!analytics) return

  analytics.track('Filter startups by tags', {
    studentId,
    filters,
  })
}

export const trackFilterStudentsByOneTag = (startupId: string, tag: string, section: string): void => {
  const analytics = getAnalytics()
  if (!analytics) return

  analytics.track('Set tag to filter students', {
    startupId,
    tag,
    section,
  })
}

export const trackFilterStudentsByTags = (startupId: string, filters: string): void => {
  const analytics = getAnalytics()
  if (!analytics) return

  analytics.track('Filter students by tags', {
    startupId,
    filters,
  })
}

export const trackClickStartupToFavorite = (startupId: string, isFavorite): void => {
  const analytics = getAnalytics()
  if (!analytics) return

  analytics.track('Click Add Startup to favorite', {
    startupId,
    isFavorite,
  })
}

export const trackOpenStartupSite = (startupId: string): void => {
  const analytics = getAnalytics()
  if (!analytics) return

  analytics.track('Open Startup site', {
    startupId,
  })
}

export const trackClickStartupLinkedIn = (startupId: string): void => {
  const analytics = getAnalytics()
  if (!analytics) return

  analytics.track('Click Startup LinkedIn page', {
    startupId,
  })
}

export const trackClickStudentLinkedIn = (studentId: string): void => {
  const analytics = getAnalytics()
  if (!analytics) return

  analytics.track('Click Student LinkedIn page', {
    studentId,
  })
}

export const trackClickStudentResume = (studentId: string): void => {
  const analytics = getAnalytics()
  if (!analytics) return

  analytics.track('Click Student resume', {
    studentId,
  })
}

export const trackClickReportJobPosting = (startupId: string, jobPostingId: string): void => {
  const analytics = getAnalytics()
  if (!analytics) return

  analytics.track('Click Report Job Posting ', {
    startupId,
    jobPostingId,
  })
}

export const trackUseSearch = (userId: string, query = ''): void => {
  const analytics = getAnalytics()
  if (!analytics) return

  analytics.track('Use search', {
    userId,
    query,
  })
}

export const trackClickNewJobPosting = (startupId: string, jobPostingId: string): void => {
  const analytics = getAnalytics()
  if (!analytics) return

  analytics.track('Click New job posting', {
    startupId,
    jobPostingId,
  })
}

export const trackShowStudentLargeAvatar = (studentId: string): void => {
  const analytics = getAnalytics()
  if (!analytics) return

  analytics.track('Show Student large avatar', {
    studentId,
  })
}
