/* eslint-disable import/prefer-default-export */
export enum UserType {
  Student = 'student',
  Startup = 'startup',
}

// Because string enums don't provide reverse mapping out of the box
// aka (string) => UserType
// we supply it ourselves (https://stackoverflow.com/questions/54297376/getting-the-enum-key-with-the-value-string-reverse-mapping-in-typescript)
export function getUserTypeForString(value: string): UserType {
  const [key] = Object.keys(UserType).filter(x => UserType[x] === value)

  return UserType[key]
}
