import { mutate } from 'swr'
import fetch from 'isomorphic-unfetch'
import { apiServer } from '../../lib/config'

export default async function updateCompany(recordId: string, data: any) {
  try {
    const response = await fetch(`/api/company/${recordId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data }),
    })
    if (!response.ok) throw new Error(response.statusText)
    mutate(`/api/company/${recordId}`)
    mutate('/api/companies')

    return await response.json()
  } catch (e) {
    return e
  }
}
